



































import { Vue, Component, Watch } from 'vue-property-decorator'
import IntervalsComponent from '@/components/TimeLogManagement/Intervals.vue'
import ListComponent from '@/components/TimeLogManagement/List.vue'
import { vxm } from '@/store'
import gql from 'graphql-tag'
import { User } from '@/types/user'
import { UserInterval } from '@/types/intervals'

@Component({
  components: {
    IntervalsComponent,
    ListComponent,
  },
})
export default class extends Vue {
  protected onFetch = false
  protected selectedInterval = 0
  @Watch('selectedInterval') onIntervalChange(val: number) {
    this.fetchInterval(val)
  }
  protected workDay = 0
  protected userIntervals: Partial<User>[] = []
  protected headers = [
    { text: 'ID', align: 'center' },
    { text: 'Name', align: 'center' },
    { text: 'Position', align: 'center' },
    { text: 'Base', align: 'center' },
    { text: 'WH', align: 'center' },
    { text: 'CR', align: 'center' },
    { text: 'OT', align: 'center' },
    { text: 'OT Mult.', align: 'center' },
    { text: 'OT Amount', align: 'center' },
    { text: 'Extra', align: 'center' },
    { text: 'Salary', align: 'center' },
    { text: 'Remark', align: 'center' },
    { text: 'Action', align: 'center' },
  ]
  protected filter = 'default'

  protected get filterUserIntervals() {
    switch (this.filter) {
      case 'all':
        return this.userIntervals
      case 'default':
        return this.userIntervals.filter(el => {
          const data = el.userInterval?.remark || 'grey'
          return data !== 'red'
        })
      default:
        return this.userIntervals.filter(el => {
          const data = el.userInterval?.remark || 'grey'
          return data === this.filter
        })
    }
  }

  protected get shouldDark() {
    return !vxm.themeToggle.isDarkMode
  }

  protected updateWorkDay(val: number) {
    this.workDay = val
  }

  protected async fetchInterval(intervalId: number) {
    try {
      this.onFetch = true
      const { data } = await this.$apollo.query<{ users: Partial<User>[] }>({
        query: gql`
          query FetchUserWithUserInterval($intervalId: Float!) {
            users(position: staff) {
              id
              username
              firstName
              nickname
              userInterval(intervalId: $intervalId) {
                id
                baseSalary
                extra
                position
                remark
                otMultiplier
                workHour
                courseHour
              }
            }
          }
        `,
        variables: {
          intervalId: intervalId,
        },
        fetchPolicy: 'no-cache',
      })
      this.userIntervals = data.users
    } catch (error) {
      // empty
    } finally {
      this.onFetch = false
    }
  }

  protected updateUserIntervalData(
    userId: number,
    data: Partial<UserInterval>
  ) {
    const thisUser = this.userIntervals.find(el => el.id === userId)
    if (thisUser) {
      thisUser.userInterval = data
    }
  }
}
