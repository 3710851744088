














































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { User } from '@/types/user'
import gql from 'graphql-tag'
import { UserInterval } from '@/types/intervals'

@Component
export default class extends Vue {
  @Prop() readonly intervalId!: number
  @Prop() readonly userInterval!: Partial<User>
  @Prop() readonly workDay!: number
  @Watch('userInterval', { immediate: true })
  onUserIntervalChange() {
    this.setData()
  }
  @Prop() readonly updateFunc!: (
    userId: number,
    data: Partial<UserInterval>
  ) => void
  protected userIntervalId = -1
  protected onEdit = false
  protected position = '-'
  protected baseSalary = 0
  protected otMultiplier = 1
  protected extra = '0'
  protected remark = 'grey'
  protected remarks = ['grey', 'red', 'green', 'blue', 'orange', 'white']
  protected onUpdate = false

  protected get userName() {
    return `${this.userInterval.nickname} ${this.userInterval.firstName}`
  }

  protected get workHour() {
    return this.userInterval.userInterval?.workHour || 0
  }

  protected get courseHour() {
    return this.userInterval.userInterval?.courseHour || 0
  }

  protected get ot() {
    return Math.max(this.workHour - this.courseHour - 8 * this.workDay, 0)
  }

  protected get otAmount() {
    const ot = this.ot
    const base = +this.baseSalary
    const otMultiplier = +this.otMultiplier
    const otCredit = (base / (30 * 8)) * otMultiplier
    return ot * otCredit
  }

  protected get salary() {
    const base = +this.baseSalary
    const ot = this.otAmount
    return base + ot + +this.extra
  }

  protected setData() {
    this.userIntervalId = this.userInterval.userInterval?.id || -1
    this.position = this.userInterval.userInterval?.position || '-'
    this.baseSalary = this.userInterval.userInterval?.baseSalary || 0
    this.otMultiplier = this.userInterval.userInterval?.otMultiplier || 1
    const extraStr = this.userInterval.userInterval?.extra || '{}'
    const extraObj = JSON.parse(extraStr)
    if (extraObj && extraObj.extra) {
      this.extra = extraObj.extra
    } else {
      this.extra = '0'
    }
    this.remark = this.userInterval.userInterval?.remark || 'grey'
  }

  protected addOrEditUserInterval() {
    const userIntervalId = this.userInterval.userInterval?.id
    if (userIntervalId) {
      this.editUserInterval(userIntervalId)
    } else {
      this.addUserInterval()
    }
  }

  protected async addUserInterval() {
    if (this.userInterval.id && this.intervalId) {
      this.onUpdate = true
      try {
        const { data } = await this.$apollo.mutate<{
          createUserInterval: Partial<UserInterval>
        }>({
          mutation: gql`
            mutation CreateNewUserInterval($data: CreateUserInterval!) {
              createUserInterval(data: $data) {
                id
                baseSalary
                extra
                position
                remark
                otMultiplier
                workHour
              }
            }
          `,
          variables: {
            data: {
              userId: this.userInterval.id,
              intervalId: this.intervalId,
              baseSalary: +this.baseSalary,
              extra: JSON.stringify({ extra: this.extra }),
              position: this.position,
              otMultiplier: +this.otMultiplier,
              remark: this.remark,
            },
          },
        })
        if (data) {
          this.updateFunc(this.userInterval.id, data.createUserInterval)
          this.setData()
        }
      } catch (error) {
        this.setData()
      } finally {
        this.onEdit = false
        this.onUpdate = false
      }
    }
  }

  protected async editUserInterval(userIntervalId: number) {
    this.onUpdate = true
    try {
      const { data } = await this.$apollo.mutate<{
        editUserInterval: Partial<UserInterval>
      }>({
        mutation: gql`
          mutation EditUserIntervalData($data: EditUserInterval!) {
            editUserInterval(data: $data) {
              id
              baseSalary
              extra
              position
              remark
              otMultiplier
              workHour
            }
          }
        `,
        variables: {
          data: {
            id: userIntervalId,
            baseSalary: +this.baseSalary,
            extra: JSON.stringify({ extra: this.extra }),
            position: this.position,
            otMultiplier: +this.otMultiplier,
            remark: this.remark,
          },
          id: userIntervalId,
        },
      })
      if (data && this.userInterval.id) {
        this.updateFunc(this.userInterval.id, data.editUserInterval)
      }
    } catch (error) {
      this.setData()
    } finally {
      this.onEdit = false
      this.onUpdate = false
    }
  }
}
