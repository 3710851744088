


















































































































































import { Vue, Component, PropSync, Prop, Watch } from 'vue-property-decorator'
import gql from 'graphql-tag'
import { Interval, Intervals } from '@/types/intervals'
import { format } from 'date-fns'
import { vxm } from '@/store'

@Component
export default class extends Vue {
  protected onFetch = true
  protected intervals: Partial<Interval>[] = []
  @PropSync('interval', { type: Number }) selectedInterval!: number
  protected intervalDialogType = ''
  protected intervalDialog = false
  protected pickerDates: string[] = []
  protected workDay = '0'
  @Prop() readonly updateWorkDay!: (val: number) => void
  @Watch('selectedInterval', { immediate: true })
  onIntervalChange(val: number) {
    this.setThisWorkDay(val)
    if (val) {
      vxm.timelog.updateSelectIntervalId(val)
    }
  }
  protected onUpdate = false
  protected remarks = [
    'default',
    'all',
    'grey',
    'red',
    'green',
    'blue',
    'white',
  ]
  @PropSync('filter', { type: String }) remark!: string
  protected cloneDialog = false
  protected cloneIntervals: Partial<Interval>[] = []
  protected cloneInterval = 0
  protected cloningData = false

  protected get intervalItems() {
    return this.intervals.map(el => ({ text: el.name, value: el.id }))
  }

  protected get cloneIntervalItems() {
    return this.cloneIntervals.map(el => ({ text: el.name, value: el.id }))
  }

  mounted() {
    this.fetchIntervals()
  }

  protected async fetchIntervals() {
    try {
      const res = await this.$apollo.query<Intervals>({
        query: gql`
          query FetchIntervals {
            intervals {
              id
              name
              startDate
              endDate
              workDay
            }
          }
        `,
      })
      this.intervals = res.data.intervals.sort((a, b) => {
        const dateA = a.endDate ? new Date(a.endDate) : new Date(0)
        const dateB = b.endDate ? new Date(b.endDate) : new Date(0)
        return dateB.getTime() - dateA.getTime()
      })
      if (this.intervals.length > 0) {
        this.selectedInterval = vxm.timelog.getSelectIntervalId
      }
    } catch (error) {
      // empty
    } finally {
      this.onFetch = false
    }
  }

  protected setThisWorkDay(val: number) {
    const thisInterval = this.intervals.find(e => e.id === val)
    if (thisInterval && thisInterval.workDay) {
      this.updateWorkDay(thisInterval.workDay)
    } else {
      this.updateWorkDay(0)
    }
  }

  protected setupEditInterval() {
    const thisInterval = this.intervals.find(
      el => el.id === this.selectedInterval
    )
    if (thisInterval && thisInterval.startDate && thisInterval.endDate) {
      const startDate = format(new Date(thisInterval.startDate), 'yyyy-MM-dd')
      const endDate = format(new Date(thisInterval.endDate), 'yyyy-MM-dd')
      this.pickerDates = [startDate, endDate]
      this.workDay = thisInterval.workDay + '' || '0'
    }
  }

  protected closeIntervalDialog() {
    this.intervalDialog = false
    this.pickerDates = []
    this.workDay = '0'
    this.intervalDialogType = ''
  }

  protected datePickerStringToDate(str: string): Date {
    const [year, month, day] = str.split('-')
    const date = new Date()
    date.setFullYear(+year, +month - 1, +day)
    date.setHours(0, 0, 0, 0)
    return date
  }

  protected changeDatePickerStringFormat(str: string): string {
    const dateStr = str.split('-')
    dateStr.reverse()
    return dateStr.join('/')
  }

  protected async addNewInterval() {
    this.onUpdate = true
    const sortedDate = this.pickerDates.sort((a, b) => {
      if (a > b) {
        return 1
      }
      return -1
    })
    const startDate = this.datePickerStringToDate(sortedDate[0])
    const endDate = this.datePickerStringToDate(sortedDate[1])
    endDate.setHours(23, 59, 59, 999)
    const name = `${this.changeDatePickerStringFormat(
      sortedDate[0]
    )} ~ ${this.changeDatePickerStringFormat(sortedDate[1])}`
    try {
      const { data } = await this.$apollo.mutate<{
        createInterval: Partial<Interval>
      }>({
        mutation: gql`
          mutation CreateNewInterval($data: CreateIntervalMutation!) {
            createInterval(data: $data) {
              id
              name
              startDate
              endDate
              workDay
            }
          }
        `,
        variables: {
          data: {
            name: name,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
            workDay: Number(this.workDay),
          },
        },
      })
      if (data) {
        this.intervals.push(data.createInterval)
      }
    } catch (error) {
      // empty
    } finally {
      this.closeIntervalDialog()
      this.onUpdate = false
    }
  }

  protected async editInterval() {
    this.onUpdate = true
    const sortedDate = this.pickerDates.sort((a, b) => {
      if (a > b) {
        return 1
      }
      return -1
    })
    const startDate = this.datePickerStringToDate(sortedDate[0])
    const endDate = this.datePickerStringToDate(sortedDate[1])
    endDate.setHours(23, 59, 59, 999)
    const name = `${this.changeDatePickerStringFormat(
      sortedDate[0]
    )} ~ ${this.changeDatePickerStringFormat(sortedDate[1])}`
    try {
      const { data } = await this.$apollo.mutate<{
        editInterval: Partial<Interval>
      }>({
        mutation: gql`
          mutation EditOldInterval($data: EditIntervalMutation!, $id: Float!) {
            editInterval(data: $data, id: $id) {
              id
              name
              startDate
              endDate
              workDay
            }
          }
        `,
        variables: {
          data: {
            name: name,
            startDate: startDate,
            endDate: endDate,
            workDay: Number(this.workDay),
          },
          id: this.selectedInterval,
        },
      })
      if (data) {
        const interval = this.intervals.find(
          el => el.id == data.editInterval.id
        )
        if (interval) {
          interval.name = data.editInterval.name
          interval.startDate = data.editInterval.startDate
          interval.endDate = data.editInterval.endDate
          interval.workDay = data.editInterval.workDay
          this.updateWorkDay(data.editInterval.workDay || 0)
        }
      }
    } catch (error) {
      // empty
    } finally {
      this.closeIntervalDialog()
      this.onUpdate = false
    }
  }

  protected setupCloneInterval() {
    this.cloneIntervals = this.intervals.filter(
      e => e.id !== this.selectedInterval
    )
  }

  protected async cloneData() {
    this.cloningData = true
    try {
      const res = await this.$apollo.query<{ interval: Interval }>({
        query: gql`
          query FetchBaseInterval($id: Float!) {
            interval(id: $id) {
              id
              userIntervals {
                id
                baseSalary
                extra
                position
                remark
                otMultiplier
                userId
              }
            }
          }
        `,
        variables: {
          id: this.cloneInterval,
        },
      })
      if (res.data && res.data.interval && res.data.interval.userIntervals) {
        const promise = []
        for (const i of res.data.interval.userIntervals) {
          if (i.userId) {
            const req = this.$apollo.mutate({
              mutation: gql`
                mutation CloneUserInterval($data: CreateUserInterval!) {
                  createUserInterval(data: $data) {
                    id
                  }
                }
              `,
              variables: {
                data: {
                  userId: i.userId,
                  intervalId: this.selectedInterval,
                  baseSalary: i.baseSalary || 0,
                  otMultiplier: i.otMultiplier || 1,
                  position: i.position || '-',
                  remark:
                    i.remark === 'red' || i.remark === 'blue'
                      ? i.remark
                      : 'grey',
                },
              },
            })
            promise.push(req)
          }
        }
        await Promise.all(
          promise.map(p =>
            p.catch(e => {
              console.log(e)
            })
          )
        )
      }
    } catch (error) {
      // empty
    } finally {
      window.location.reload()
    }
  }
}
